// @ts-ignore
import { SCORM } from "pipwerks-scorm-api-wrapper";

type Data = null | {
  [prop: string]: any;
};

export enum Status {
  passed = "passed",
}

const scorm = {
  init() {
    SCORM.init();
  },

  isConnected(): boolean {
    return SCORM.connection.isActive;
  },

  getData(): Data {
    try {
      return JSON.parse(SCORM.get("cmi.suspend_data") ?? "{}");
    } catch {
      return null;
    }
  },

  setData(data: Data) {
    const currentData = this.getData();
    SCORM.set(
      "cmi.suspend_data",
      data === null ? "" : JSON.stringify({ ...currentData, ...data })
    );
  },

  getProgress(): number {
    return SCORM.get("cmi.core.score.raw"); // 0 - 100
  },

  setProgress() {
    const score = SCORM.get("cmi.core.score.raw") ?? 0;
    SCORM.set("cmi.core.score.raw", Number(score) + 100);
  },

  setStatus(status: Status) {
    SCORM.set("cmi.core.lesson_status", status);
  },

  getStatus(): Status {
    return SCORM.set("cmi.core.lesson_status");
  },

  complete() {
    this.setStatus(Status.passed);
    SCORM.save();
  },
};

export default scorm;
