import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AppReset } from "./app.styles";
import scorm from "../../services/scorm";

const Home = React.lazy(() => import("../../pages/home/home"));
const Scene = React.lazy(() => import("../../pages/scene/scene"));

export default function App() {
  const navigate = useNavigate();
  React.useEffect(() => {
    scorm.init();
    if (!scorm.isConnected()) {
      console.log("Not connected to scorm");
      return;
    }
    const data = scorm.getData();
    const progress = scorm.getProgress();
    const status = scorm.getStatus();
    console.log("data", data);
    console.log("progress", progress);
    console.log("status", status);
    if (data?.location && data?.location === "scene") {
      navigate("/scene");
    }
  }, [navigate]);

  return (
    <React.Suspense fallback="Loading...">
      <AppReset />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/scene" element={<Scene />} />
      </Routes>
    </React.Suspense>
  );
}
